<template>
  <div class="invoice-list-container">
    <b-row
      v-if="analytics"
      class="match-height"
    >

      <b-col>
        <card-statistic
          title="Tổng tiền"
          :value="Number(analytics.total).toLocaleString()"
          icon="CreditCardIcon"
          color="primary"
        />
      </b-col>

      <b-col>
        <card-statistic
          title="Đã thu"
          :value="Number(analytics.paid).toLocaleString()"
          icon="CreditCardIcon"
          color="success"
        />
      </b-col>
      <b-col>
        <card-statistic
          title="Phải thu"
          :value="Number(analytics.remain).toLocaleString()"
          icon="CreditCardIcon"
          color="danger"
        />
      </b-col>

    </b-row>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Hoá đơn</b-card-title>
        <crud-buttons
          name="Hóa đơn"
          :selected-rows="selectedRows"
          :show-import="$can('create', 'invoice')"
          :show-export="true"
          modal="modal-invoice"
          :enable-delete="setDeleteEnable(selectedRows) && $can('delete', 'invoice')"
          :enable-add="$can('create', 'invoice')"
          :hide-delete="!selectedRows || selectedRows.length === 0"
          :show-school="true"
          :show-class="true"
          @download-template-file="downloadImportTemplate"
          @on-add="resetItem"
          @on-delete="onDelete"
          @on-export="exportData"
          @on-import-file="importData"
        >
          <template
            slot="other-buttons"
          >

            <b-button
              v-if="$can('approve', 'invoice')"
              v-b-tooltip.hover.v-success
              variant="success"
              class="btn-icon ml-50"
              title="Duyệt"
              :hidden="setApproveHidden(selectedRows)"
              @click="onApproveClick(selectedRows)"
            >
              <feather-icon icon="CheckSquareIcon" />
            </b-button>
            <b-button
              v-if="$can('approve', 'invoice')"
              v-b-tooltip.hover.v-secondary
              variant="secondary"
              class="btn-icon ml-50"
              title="Bỏ duyệt"
              :hidden="setDeclineHidden(selectedRows)"
              @click="onDeclineClick(selectedRows)"
            >
              <feather-icon icon="XIcon" />
            </b-button>
            <b-button
              v-if="$can('read', 'invoice')"
              v-b-tooltip.hover.v-secondary
              variant="secondary"
              class="btn-icon ml-50"
              title="Tải hóa đơn PDF"
              @click="exportZipPdf()"
            >
              <feather-icon icon="FolderIcon" />
            </b-button>
            <b-button
              v-if="$can('read', 'invoice')"
              v-b-tooltip.hover.v-secondary
              variant="secondary"
              class="btn-icon ml-50"
              title="Tải ảnh hóa đơn"
              @click="exportZipImages()"
            >
              <feather-icon icon="ImageIcon" />
            </b-button>
            <b-button
              v-if="$can('read', 'invoice')"
              v-b-tooltip.hover.v-warning
              v-b-modal.modal-send-invoice
              variant="warning"
              class="btn-icon ml-50"
              title="Gửi hóa đơn"
            >
              <feather-icon icon="SendIcon" />
            </b-button>

          </template>
        </crud-buttons>
      </b-card-header>
      <b-card-body>

        <b-row>
          <b-col>
            <select-school
              v-model="school"
              :label="null"
            />
          </b-col>
          <b-col>
            <select-class
              v-model="classObject"
              :school="school"
            />
          </b-col>
          <b-col>
            <select-student
              v-model="student"
              :school="school"
              :class-object="classObject"
            />
          </b-col>
          <b-col>
            <b-form-input
              v-model="searchTerm"
              placeholder="Tìm kiếm..."
              debounce="1000"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <select-month
              v-model="month"
              :default-value="month"
            />
          </b-col>
          <b-col>
            <select-approve-status
              v-model="approveStatus"
              :default-value="approveStatus"
            />
          </b-col>

          <b-col>
            <select-payment-status
              v-model="paymentStatus"
              :default-value="paymentStatus"
            />
          </b-col>
          <b-col>
            <select-due-date
              v-model="dueDateStatus"
              :default-value="dueDateStatus"
            />
          </b-col>

        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table
              style-class="vgt-table striped bordered"
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isLoading"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: false,
              }"
              :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage: serverParams.perPage,
              }"
              @on-selected-rows-change="selectionChanged"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="loadingContent">
                <b-spinner
                  label="Loading"
                  type="grow"
                />
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Code -->
                <span v-if="props.column.field === 'code'">
                  <b-link
                    v-b-modal.modal-invoice-preview
                    class="font-weight-bold"
                    @click="onEditItem(props.row)"
                  >
                    {{ props.row.code }}
                  </b-link>
                </span>
                <!-- Column: Customer -->
                <span v-else-if="props.column.field === 'name'">
                  <span>{{ props.row.customerName }}</span>
                  <small
                    v-if="props.row.customerPhone"
                    class="text-muted"
                  ><br>{{ props.row.customerPhone }}</small>
                  <small
                    v-if="props.row.customerEmail"
                    class="text-muted"
                  ><br>{{ props.row.customerEmail }}</small>

                </span>
                <!-- Column: Customer -->
                <span v-else-if="props.column.field === 'customer'">
                  <div>
                    <span v-if="props.row.contract">{{ props.row.contract.name }}</span>
                    <span v-if="props.row.reservation && props.row.reservation.tenant">{{ props.row.reservation.tenant.name }}</span>
                    <span
                      v-if="props.row.apartment"
                      class="text-muted"
                    ><br>Tòa nhà: {{ props.row.apartment.name }}</span>
                    <span
                      v-if="props.row.room"
                      class="text-muted"
                    ><br>Phòng: {{ props.row.room.name }}</span>
                    <span
                      v-if="props.row.bed"
                      class="text-muted"
                    ><br>Giường: {{ props.row.bed.name }}</span>
                  </div>
                </span>

                <!-- Column: Total -->
                <span v-else-if="props.column.field === 'total'">
                  {{ Number(props.row.total).toLocaleString() }}
                </span>

                <!-- Column: Paid -->
                <span v-else-if="props.column.field === 'paid'">
                  <b-link
                    class="font-weight-bold"
                    @click="onEditItem(props.row)"
                  >{{ Number(props.row.paid + props.row.prepaid).toLocaleString() }}</b-link>
                </span>

                <!-- Column: Remain -->
                <span v-else-if="props.column.field === 'remain'">
                  {{ Number(props.row.remain).toLocaleString() }}
                </span>

                <!-- Column: Remain -->
                <span v-else-if="props.column.field === 'totalDebt'">
                  {{ Number(props.row.totalDebt).toLocaleString() }}
                </span>

                <!-- Column: Due date -->
                <span
                  v-else-if="props.column.field === 'dueDate'"
                  class="flex"
                >
                  <div>
                    <span>
                      {{ parseDateToString(props.row.dueDate) }}
                    </span>

                    <b-badge
                      v-if="props.row.remain !== 0"
                      pill
                      :variant="getRemainDaysVariant(props.row.remainDays).variant"
                      class="font-small-1"
                    >
                      {{ getRemainDaysVariant(props.row.remainDays).text }}
                    </b-badge>
                  </div>

                </span>

                <span
                  v-else-if="props.column.field === 'month'"
                  class="flex"
                >
                  <div>
                    <span>
                      {{ parseDateToString(props.row.month, 'MM-YYYY') }}
                    </span>

                  </div>

                </span>

                <!-- Column: Paid -->
                <span v-else-if="props.column.field === 'approve'">

                  <b-badge
                    pill
                    class="size-18"
                    :variant="`light-${resolveInvoiceApproveStatusVariantAndIcon(props.row.approve).variant}`"
                  >{{ resolveInvoiceApproveStatusVariantAndIcon(props.row.approve).title }}</b-badge>

                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <table-actions
                    name="Hóa đơn"
                    :disable-delete="props.row.approve"
                    :disable-edit="false"
                    :hide-print="false"
                    :hide-payment="props.row.remain === 0 || !props.row.approve || !$can('update', 'invoice')"
                    :payment-modal="'modal-invoice-payment'"
                    modal="modal-invoice"
                    :hide-delete="!$can('delete', 'invoice')"
                    :hide-edit="props.row.approve"
                    @on-delete="onDelete(props.row)"
                    @on-edit="onEditItem(props.row)"
                    @on-payment="onEditItem(props.row)"
                  >
                    <template
                      slot="other-buttons"
                    >
                      <b-button
                        v-b-modal.modal-invoice-preview
                        v-b-tooltip.hover.v-info
                        variant="info"
                        class="btn-icon mr-50"
                        title="Xem hóa đơn"
                        size="sm"
                        @click="onEditItem(props.row)"
                      >
                        <feather-icon icon="EyeIcon" />
                      </b-button>
                      <b-button

                        v-if="!props.row.approve && $can('approve', 'invoice')"
                        v-b-tooltip.hover.v-success
                        variant="success"
                        class="btn-icon mr-50"
                        title="Duyệt"
                        size="sm"
                        @click="onApproveClick([props.row])"
                      >
                        <feather-icon icon="CheckSquareIcon" />
                      </b-button>
                      <b-button
                        v-if="props.row.approve && $can('approve', 'invoice')"
                        v-b-tooltip.hover.v-secondary
                        :disabled="props.row.approve === false || parseInt(props.row.paid , 10) !== 0"
                        variant="secondary"
                        class="btn-icon mr-50"
                        title="Bỏ duyệt"
                        size="sm"
                        @click="onDeclineClick([props.row])"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                      <b-button
                        v-if="$can('pay', 'invoice')"
                        v-b-tooltip.hover.v-warning
                        v-b-modal.modal-invoice-payment
                        variant="warning"
                        class="btn-icon mr-50"
                        title="Xác nhận thanh toán"
                        size="sm"
                        :disabled="setPaymentHidden([props.row])"
                        @click="onEditItem(props.row)"
                      >
                        <feather-icon icon="CreditCardIcon" />
                      </b-button>
                    </template>
                  </table-actions>
                </span>
                <span v-else-if="props.column.field === 'creator'">
                  {{ props.row.creator && props.row.creator.user ? props.row.creator.user.name : props.row.owner.name }}
                </span>

                <!-- Column: Common -->
                <span
                  v-else
                >
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị tối đa </span>
                    <b-form-select
                      v-model="serverParams.perPage"
                      :options="['10', '20', '50', '100', '500']"
                      class="mx-1"
                      @input="
                        (value) => props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      trên tổng số {{ props.total }} kết quả
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="serverParams.perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                <div class="text-center text-muted">
                  Không có bản ghi nào!
                </div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <invoice-preview-modal
      :item="item"
      @approve-invoice="onApproveClick([item])"
      @decline-invoice="onDeclineClick([item])"
      @pay-invoice="openPayModal(item)"
    />
    <invoice-payment-modal
      ref="payment-modal"
      :item="item"
      @refetch-data="fetchData"
    />
    <payment-history-modal
      ref="payment-history-modal"
      :item="item"
      @refetch-data="fetchData"
    />
    <invoice-modal
      :item="item"
      @refetch-data="fetchData"
    />
    <invoice-send-modal
      :item="item"
      @refetch-data="fetchData"
    />
    <generate-invoice-modal />
    <create-multiple-invoice-modal />
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BLink,
  BPagination,
  BFormSelect,
  BSpinner,
  BBadge,
  BButton,
  VBModal,
  VBTooltip,
  BFormInput,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import TableActions from '@/views/components/TableActions.vue';
import CrudButtons from '@/views/components/CrudButtons.vue';
import SelectMonth from '@/views/components/SelectMonth.vue';
// eslint-disable-next-line import/no-cycle
import SelectApproveStatus from '@/views/components/SelectApproveStatus.vue';
import SelectPaymentStatus from '@/views/components/SelectPaymentStatus.vue';
import SelectDueDate from '@/views/components/SelectDueDate.vue';
import CardStatistic from '@/views/components/CardStatistic.vue';
import { parseDateToString } from '@/auth/utils';
import { nextTick } from '@vue/composition-api';
import SelectStudent from '@/views/components/SelectStudent.vue';
import useInvoiceList from './useInvoiceList';
import InvoicePreviewModal from '../preview/InvoicePreviewModal.vue';
import InvoiceModal from '../modal/InvoiceModal.vue';
import InvoicePaymentModal from '../payment/InvoicePaymentModal.vue';
import GenerateInvoiceModal from '../generate/GenerateInvoiceModal.vue';
import InvoiceSendModal from '../send/InvoiceSendModal.vue';
import CreateMultipleInvoiceModal from '../create-multiple/CreateMultipleInvoiceModal.vue';
import PaymentHistoryModal from '../payment-history/PaymentHistoryModal.vue';
import SelectSchool from '../../../components/SelectSchool.vue';
import SelectClass from '../../../components/SelectClass.vue';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BLink,
    BPagination,
    BFormSelect,
    BSpinner,
    BBadge,
    BButton,
    BFormInput,
    VueGoodTable,
    TableActions,
    CrudButtons,
    InvoicePreviewModal,
    InvoiceModal,
    InvoicePaymentModal,
    SelectMonth,
    GenerateInvoiceModal,
    SelectApproveStatus,
    SelectPaymentStatus,
    SelectDueDate,
    CardStatistic,
    InvoiceSendModal,
    CreateMultipleInvoiceModal,
    PaymentHistoryModal,
    SelectSchool,
    SelectClass,
    SelectStudent,
  },
  directives: {
    'b-modal': VBModal,
    "b-tooltip": VBTooltip,
  },
  setup() {
    const {
      analytics,
      item,
      paymentItem,
      columns,
      rows,
      school,
      classObject,
      student,
      month,
      status,
      paymentStatus,
      type,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      approveStatus,
      dueDateStatus,
      exportZipPdf,
      importData,
      exportData,
      downloadImportTemplate,
      setPaymentHidden,
      approveInvoice,
      fetchData,
      deleteInvoices,
      onEditItem,
      resetItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      resolveInvoiceApproveStatusVariantAndIcon,
      getRemainDaysVariant,
      setApproveHidden,
      setDeclineHidden,
      setDeleteEnable,
      onCreateMultiple,
      resolveColWidthIfDisableBed,
      exportZipImages,
    } = useInvoiceList();

    return {
      analytics,
      item,
      paymentItem,
      columns,
      rows,
      school,
      classObject,
      student,
      month,
      status,
      paymentStatus,
      type,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      approveStatus,
      dueDateStatus,
      exportZipPdf,
      importData,
      exportData,
      downloadImportTemplate,
      setPaymentHidden,
      approveInvoice,
      fetchData,
      deleteInvoices,
      onEditItem,
      resetItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      resolveInvoiceApproveStatusVariantAndIcon,
      getRemainDaysVariant,
      setApproveHidden,
      setDeclineHidden,
      setDeleteEnable,
      onCreateMultiple,
      resolveColWidthIfDisableBed,
      exportZipImages,
      parseDateToString,
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    openPayModal() {
      nextTick(() => {
        this.$refs['payment-modal'].$refs.refModal.show();
      });
    },
    onDelete(invoice) {
      const deleteObjects = invoice && invoice.id > 0 ? [invoice] : this.selectedRows;
      this.deleteInvoices(deleteObjects);
    },
    onApproveClick(invoices) {
      this.$bvModal
        .msgBoxConfirm(
          `Bạn đang thực hiện thao tác DUYỆT hóa đơn. Bạn có chắc chắn muốn xác nhận duyệt này không?`,
          {
            title: `Duyệt hóa đơn`,
            okTitle: 'Duyệt',
            cancelTitle: 'Huỷ',
            okVariant: 'primary',
            cancelVariant: 'outline-secondary',
          },
        )
        .then(value => {
          if (value) {
            this.approveInvoice(invoices, true);
          }
        });
    },
    onDeclineClick(invoices) {
      this.$bvModal
        .msgBoxConfirm(
          `Bạn đang thực hiện thao tác BỎ DUYỆT hóa đơn. Bạn có chắc chắn muốn hủy duyệt không?`,
          {
            title: `Bỏ duyệt hóa đơn`,
            okTitle: 'Bỏ duyệt',
            cancelTitle: 'Huỷ',
            okVariant: 'warning',
            cancelVariant: 'outline-secondary',
          },
        )
        .then(value => {
          if (value) {
            this.approveInvoice(invoices, false);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}

</style>
