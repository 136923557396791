<template>
  <b-modal
    id="modal-send-invoice"
    ref="refModalSendInvoice"
    title="Gửi hóa đơn"
    :ok-title="isSubmitting ? 'Đang gửi' : 'Gửi'"
    :ok-disabled="isSubmitting"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    scrollable
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-alert
          show
          variant="danger"
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="SendIcon"
            />
            <span class="ml-25">Hệ thống chỉ gửi hóa đơn còn nợ và đã được duyệt đến cho khách hàng</span>
          </div>
        </b-alert>
        <b-row>
          <b-col
            cols="12"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                Chọn đối tượng gửi
              </div>
            </div>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="school"
              rules=""
            >
              <select-school
                v-model="itemLocal.school"
                :filter-active="true"
                :enable="!(itemLocal.id && itemLocal.id > 0)"
                :default-value="itemLocal.school"
                label="Cơ sở"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="class"
              rules=""
            >
              <select-class
                v-model="itemLocal.class"
                :filter-active="true"
                :enable="!(itemLocal.id && itemLocal.id > 0)"
                :school="itemLocal.school"
                :default-value="itemLocal.class"
                label="Lớp học"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>

        </b-row>

        <b-row>
          <b-col
            cols="12"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                Hình thức gửi
              </div>
            </div>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="Hình thức gửi"
              rules="required"
            >
              <b-form-checkbox-group
                v-model="itemLocal.methods"
                :disabled="itemLocal.id > 0"
                :options="[
                  { text: 'Zalo thường', value: 'zalo-normal' },
                  { text: 'Email', value: 'email' },
                ]"
                class="demo-inline-spacing"
              />
              <small class="text-danger">{{ validationContext.errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>

  </b-modal>

</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BForm,
  BAlert,
  BFormCheckboxGroup,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectSchool from '@/views/components/SelectSchool.vue';
import SelectClass from '@/views/components/SelectClass.vue';
import useInvoiceSendModal from './useInvoiceSendModal';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BFormCheckboxGroup,
    SelectSchool,
    SelectClass,
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalSendInvoice,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
    } = useInvoiceSendModal(props, emit, refFormObserver);

    return {
      refModalSendInvoice,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
