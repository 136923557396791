var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"refModal",attrs:{"id":"modal-invoice-payment-history","title":"Lịch sử thanh toán","hide-footer":"","size":"lg","scrollable":"","cancel-variant":"outline-secondary","no-close-on-backdrop":""},on:{"show":_vm.showModal,"hidden":_vm.resetModal}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('vue-good-table',{staticClass:"mb-1 mt-1",attrs:{"columns":_vm.columns,"rows":_vm.rows,"select-options":{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'remain' || props.column.field === 'total' || props.column.field === 'paid')?_c('span',[_vm._v(" "+_vm._s(Number(props.row[props.column.field]).toLocaleString())+" ")]):(props.column.field === 'amount')?_c('span',[_vm._v(" "+_vm._s(Number(props.row.amount).toLocaleString())+" ")]):(props.column.field === 'issueDate')?_c('span',[_vm._v(" "+_vm._s(_vm.parseDateToString(props.row.issueDate))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('template',{slot:"loadingContent"},[_c('b-spinner',{attrs:{"label":"Loading","type":"grow"}})],1),_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('div',{staticClass:"text-center text-muted"},[_vm._v(" Không có bản ghi nào! ")])])],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }