<template>
  <b-modal
    id="modal-generate-invoice"
    ref="refModal"
    title="Tạo nhiều hóa đơn"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >

        <b-row>

          <b-col cols="12">
            <select-apartment
              v-model="itemLocal.apartment"
              :filter-active="true"
              :default-value="itemLocal.apartment"
              label="Tòa nhà"
            />
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="month"
              rules="required"
            >
              <select-month
                v-model="itemLocal.month"
                :default-value="itemLocal.month"
                required
                label="Kỳ thanh toán"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />

            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="mode"
              rules="required"
            >
              <generate-invoice-mode
                v-model="itemLocal.mode"
                :default-value="itemLocal.mode"
                required
                :append-to-body="true"
                label="Hình thức tạo hóa đơn"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />

            </validation-provider>
          </b-col>

        </b-row>

      </b-form>
    </validation-observer>

  </b-modal>

</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BForm,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectMonth from '@/views/components/SelectMonth.vue';
import GenerateInvoiceMode from '@/views/components/GenerateInvoiceMode.vue';
import useGenerateInvoiceModal from './useGenerateInvoiceModal';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BForm,
    ValidationProvider,
    ValidationObserver,
    SelectMonth,
    GenerateInvoiceMode,
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
    } = useGenerateInvoiceModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
