<!-- eslint-disable vue/no-v-html -->
<template>
  <b-modal
    id="modal-invoice-payment-history"
    ref="refModal"
    title="Lịch sử thanh toán"
    hide-footer
    size="lg"
    scrollable
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @show="showModal"
    @hidden="resetModal"
  >
    <!-- Body -->
    <b-row>
      <b-col cols="12">
        <vue-good-table
          class="mb-1 mt-1"
          :columns="columns"
          :rows="rows"
          :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
        >
          <template slot="loadingContent">
            <b-spinner
              label="Loading"
              type="grow"
            />
          </template>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Code -->
            <span v-if="props.column.field === 'remain' || props.column.field === 'total' || props.column.field === 'paid'">
              {{ Number(props.row[props.column.field]).toLocaleString() }}
            </span>

            <!-- Column: Code -->
            <span v-else-if="props.column.field === 'amount'">
              {{ Number(props.row.amount).toLocaleString() }}
            </span>

            <!-- Column: issueDate -->
            <span v-else-if="props.column.field === 'issueDate'">
              {{ parseDateToString(props.row.issueDate) }}
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <div slot="emptystate">
            <div class="text-center text-muted">
              Không có bản ghi nào!
            </div>
          </div>
        </vue-good-table>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
} from 'bootstrap-vue';
import { required } from '@validations';
import { VueGoodTable } from 'vue-good-table';
import { parseDateToString } from '@/auth/utils';
import usePaymentHistoryModal from './usePaymentHistoryModal';

export default {
  components: {
    BModal,
    VueGoodTable,
    BRow,
    BCol,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props) {
    const {
      itemLocal,
      resetItemLocal,
      resetModal,
      showModal,
      columns,
      rows,
    } = usePaymentHistoryModal(props);

    return {
      itemLocal,
      resetItemLocal,
      resetModal,
      showModal,
      columns,
      rows,
      parseDateToString,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}
</style>
