/* eslint-disable camelcase */
import { ref, watch } from '@vue/composition-api';
import store from '@/store';
import moment from 'moment';
import useJwt from '@/auth/jwt/useJwt';
import invoiceStoreModule from '../invoiceStoreModule';

export default function useCreateMultipleInvoiceModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'invoice';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, invoiceStoreModule);
  }

  const isSubmitting = ref(false);
  const refModalCreateMultiple = ref(null);
  const refForm = ref(refFormObserver);
  const apartment = ref(null);
  const month = ref(moment(new Date()).format("MM-YYYY"));
  const dueDate = ref(moment(new Date()).add(5, 'days').format("DD-MM-YYYY"));
  const issueDate = ref(moment(new Date()).format("DD-MM-YYYY"));
  const contracts = ref([]);
  const columns = ref([]);
  const fees = ref([]);
  const isLoading = ref(false);
  const resetModal = () => { };
  const onSubmit = () => { };
  const itemLocal = ref([]);

  const calculateColumns = () => {
    // Tinh toan column
    let tempColumns = [{
      label: 'Hợp đồng',
      field: 'name',
      sortable: false,
    }];
    // eslint-disable-next-line no-restricted-syntax
    for (const eachFee of fees.value) {
      if (eachFee.feeCategoryId === 'lease') {
        tempColumns = [
          ...tempColumns,
          {
            label: `${eachFee.name} (Từ ngày)`,
            field: `fee.${eachFee.id}.startDate`,
            sortable: false,
            feeId: eachFee.id,
          },
          {
            label: `${eachFee.name} (Đến ngày)`,
            field: `fee.${eachFee.id}.endDate`,
            sortable: false,
          },
          {
            label: `${eachFee.name}(Thành tiền)`,
            field: `fee.${eachFee.id}.total`,
            sortable: false,
          },
        ];
      }
      //  else if (eachFee.feeCategoryId === 'deposit') {
      //   tempColumns = [
      //     ...tempColumns,
      //     {
      //       label: eachFee.name,
      //       field: 'deposit',
      //       sortable: false,
      //     },
      //   ];
      // } else if (parseInt(eachFee.typeId, 10) === 1 || parseInt(eachFee.typeId, 10) === 2) {
      //   tempColumns = [
      //     ...tempColumns,
      //     {
      //       label: `${eachFee.name} (Chỉ số)`,
      //       field: `${eachFee.name}.meter`,
      //       sortable: false,
      //     },
      //   ];
      // } else if (parseInt(eachFee.typeId, 10) === 3) {
      //   tempColumns = [
      //     ...tempColumns,
      //     {
      //       label: `${eachFee.name} (Số lượng)`,
      //       field: `${eachFee.name}.quantity`,
      //       sortable: false,
      //     },
      //   ];
      // } else if (parseInt(eachFee.typeId, 10) === 4) {
      //   tempColumns = [
      //     ...tempColumns,
      //     {
      //       label: `${eachFee.name} (Đơn giá)`,
      //       field: `${eachFee.name}.unitPrice`,
      //       sortable: false,
      //     },
      //     {
      //       label: `${eachFee.name} (Số lượng)`,
      //       field: `${eachFee.name}.quantity`,
      //       sortable: false,
      //     },
      //   ];
      // }
    }
    tempColumns = [
      ...tempColumns,
      {
        label: "Tạm tính",
        field: "subTotal",
        sortable: false,
      },
      {
        label: "Giảm giá",
        field: "discount",
        sortable: false,
      },
      {
        label: "Thuế(%)",
        field: "tax",
        sortable: false,
      },
      {
        label: "Tổng tiền",
        field: "total",
        sortable: false,
      },
      {
        label: "Ghi chú",
        field: "note",
        sortable: false,
      },
    ];
    columns.value = tempColumns;
  };

  const calculateLocalFees = contract => fees.value.map(object => {
    if (object.feeCategoryId === 'lease') {
      const startDate = moment(contract.lastBillingDate).isValid() ? moment(contract.lastBillingDate).add(1, 'd') : moment(contract.billingDate);
      const endDate = startDate.clone().add(parseInt(contract.paymentPeriod.value, 10), 'month').subtract(1, 'd');

      const quantityItem = contract.paymentPeriod.value;
      const { price } = contract;
      const totalAmount = Number(price) * Number(quantityItem);

      return {
        id: object.id,
        name: object.name,
        feeCategoryId: object.feeCategoryId,
        typeId: object.typeId,
        quantity: 1,
        coefficient: 1,
        startDate: startDate.format("DD-MM-YYYY"),
        endDate: endDate.format("DD-MM-YYYY"),
        price,
        quota: null,
        meter: null,
        total: totalAmount,
      };
    }
    return {
      id: object.id,
      name: object.name,
      feeCategoryId: object.feeCategoryId,
      typeId: object.typeId,
      quantity: 1,
      coefficient: 1,
      startDate: null,
      endDate: null,
      price: object.price,
      quota: object.quota,
      meter: null,
      total: 0,
    };
  });
  const fetchFees = () => {
    isLoading.value = true;
    useJwt.getFees().then(response => {
      fees.value = response.data.items.reverse();
      calculateColumns();
      isLoading.value = false;
    });
  };

  const openModal = () => {
    fetchFees();
  };

  const convertToLocalData = () => {
    const temp = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const eachContract of contracts.value) {
      temp.push({
        contract: { id: eachContract.id, name: eachContract.name },
        apartment: { id: apartment.value.id, name: apartment.value.name },
        room: eachContract.room ? { id: eachContract.roomId, name: eachContract.room.name } : null,
        bed: eachContract.bedId && eachContract.bedId > 0 ? { id: eachContract.bedId, name: '' } : null,
        fees: calculateLocalFees(eachContract),
        subTotal: 0,
        tax: 0,
        discount: 0,
        total: 0,
        note: '',
      });
    }
    itemLocal.value = temp;
  };

  watch(apartment, val => {
    store.dispatch('contract/fetchContracts', { filter: { apartmentId: val.id } }).then(response => {
      contracts.value = response.data.items;

      convertToLocalData();
    });
  });

  return {
    refModalCreateMultiple,
    refForm,
    itemLocal,
    columns,
    isLoading,
    isSubmitting,
    resetModal,
    onSubmit,
    apartment,
    month,
    dueDate,
    issueDate,
    contracts,
    openModal,
  };
}
